export let softs = [
 {
  "id": "1",
  "ini": "こ",
  "name": "ゴルフ",
  "maker": "任天堂",
  "releaseDate": "1986/2/21",
  "price": "2500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "2",
  "ini": "さ",
  "name": "サッカー",
  "maker": "任天堂",
  "releaseDate": "1986/2/21",
  "price": "2500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "3",
  "ini": "す",
  "name": "スーパーマリオブラザーズ",
  "maker": "任天堂",
  "releaseDate": "1986/2/21",
  "price": "2500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "4",
  "ini": "せ",
  "name": "ゼルダの伝説",
  "maker": "任天堂",
  "releaseDate": "1986/2/21",
  "price": "2600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "5",
  "ini": "て",
  "name": "テニス",
  "maker": "任天堂",
  "releaseDate": "1986/2/21",
  "price": "2500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "6",
  "ini": "へ",
  "name": "ベースボール",
  "maker": "任天堂",
  "releaseDate": "1986/2/21",
  "price": "2500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "7",
  "ini": "ま",
  "name": "麻雀",
  "maker": "任天堂",
  "releaseDate": "1986/2/21",
  "price": "2500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "8",
  "ini": "な",
  "name": "謎の村雨城",
  "maker": "任天堂",
  "releaseDate": "1986/4/14",
  "price": "2600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "9",
  "ini": "す",
  "name": "スーパーマリオブラザーズ2",
  "maker": "任天堂",
  "releaseDate": "1986/6/3",
  "price": "2500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "10",
  "ini": "は",
  "name": "バレーボール",
  "maker": "任天堂",
  "releaseDate": "1986/7/21",
  "price": "2500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "11",
  "ini": "な",
  "name": "涙の倉庫番スペシャル",
  "maker": "アスキー",
  "releaseDate": "1986/7/30",
  "price": "2980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "12",
  "ini": "め",
  "name": "メトロイド",
  "maker": "任天堂",
  "releaseDate": "1986/8/6",
  "price": "2600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "13",
  "ini": "あ",
  "name": "アイアムアティーチャー スーパーマリオのセーター",
  "maker": "ロイヤル工業",
  "releaseDate": "1986/8/27",
  "price": "2900",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "14",
  "ini": "こ",
  "name": "子猫物語",
  "maker": "ポニーキャニオン",
  "releaseDate": "1986/9/19",
  "price": "2900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "15",
  "ini": "あ",
  "name": "アイアムアティーチャー 手あみのきそ",
  "maker": "ロイヤル工業",
  "releaseDate": "1986/9/26",
  "price": "2900",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "16",
  "ini": "あ",
  "name": "悪魔城ドラキュラ",
  "maker": "コナミ",
  "releaseDate": "1986/9/26",
  "price": "2980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "17",
  "ini": "お",
  "name": "Othello",
  "maker": "河田",
  "releaseDate": "1986/10/13",
  "price": "2980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "18",
  "ini": "ふ",
  "name": "プロレス",
  "maker": "任天堂",
  "releaseDate": "1986/10/21",
  "price": "2500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "19",
  "ini": "き",
  "name": "銀河伝承 GALAXY ODYSSEY",
  "maker": "イマジニア",
  "releaseDate": "1986/11/6",
  "price": "5000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "20",
  "ini": "か",
  "name": "ガルフォース",
  "maker": "HAL研究所",
  "releaseDate": "1986/11/19",
  "price": "2980",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "21",
  "ini": "て",
  "name": "デット・ゾーン",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1986/11/20",
  "price": "2980",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "22",
  "ini": "も",
  "name": "もえろツインビー シナモン博士を救え！",
  "maker": "コナミ",
  "releaseDate": "1986/11/21",
  "price": "3100",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "23",
  "ini": "き",
  "name": "きね子",
  "maker": "アイレム",
  "releaseDate": "1986/11/28",
  "price": "3300",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "24",
  "ini": "さ",
  "name": "ザナック",
  "maker": "ポニーキャニオン",
  "releaseDate": "1986/11/28",
  "price": "2900",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "25",
  "ini": "あ",
  "name": "アディアンの杖",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1986/12/12",
  "price": "4900",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "26",
  "ini": "な",
  "name": "謎の壁 ブロックくずし",
  "maker": "コナミ",
  "releaseDate": "1986/12/13",
  "price": "2980",
  "genre": "ブロック崩し",
  "icNo": "1"
 },
 {
  "id": "27",
  "ini": "す",
  "name": "水晶の龍",
  "maker": "DOG（スクウェア）",
  "releaseDate": "1986/12/15",
  "price": "3400",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "28",
  "ini": "ふ",
  "name": "ブリーダー",
  "maker": "ソフトプロ",
  "releaseDate": "1986/12/15",
  "price": "2980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "29",
  "ini": "な",
  "name": "ナイト・ロアー 魔城の狼男",
  "maker": "ジャレコ",
  "releaseDate": "1986/12/19",
  "price": "3200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "30",
  "ini": "ひ",
  "name": "光神話 パルテナの鏡",
  "maker": "任天堂",
  "releaseDate": "1986/12/19",
  "price": "2600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "31",
  "ini": "ま",
  "name": "魔洞戦記 ディープダンジョン",
  "maker": "DOG（スクウェア）",
  "releaseDate": "1986/12/19",
  "price": "3400",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "32",
  "ini": "き",
  "name": "消えたプリンセス",
  "maker": "イマジニア",
  "releaseDate": "1986/12/20",
  "price": "5000",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "33",
  "ini": "ふ",
  "name": "プロフェッショナル 麻雀悟空",
  "maker": "アスキー",
  "releaseDate": "1986/12/25",
  "price": "2980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "34",
  "ini": "え",
  "name": "エレクトリシャン",
  "maker": "コトブキシステム",
  "releaseDate": "1986/12/26",
  "price": "2980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "35",
  "ini": "り",
  "name": "リンクの冒険",
  "maker": "任天堂",
  "releaseDate": "1987/1/14",
  "price": "2600",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "36",
  "ini": "う",
  "name": "ウルトラマン 怪獣帝国の逆襲",
  "maker": "バンダイ",
  "releaseDate": "1987/1/29",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "37",
  "ini": "え",
  "name": "エッガーランド",
  "maker": "HAL研究所",
  "releaseDate": "1987/1/29",
  "price": "2900",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "38",
  "ini": "な",
  "name": "ナゾラーランド 増刊号",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1987/2/6",
  "price": "2980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "39",
  "ini": "え",
  "name": "エスパードリーム",
  "maker": "コナミ",
  "releaseDate": "1987/2/20",
  "price": "2980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "40",
  "ini": "こ",
  "name": "ゴルフ JAPANコース",
  "maker": "任天堂",
  "releaseDate": "1987/2/21",
  "price": "3500",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "41",
  "ini": "き",
  "name": "きね子 Vol.Ⅱ",
  "maker": "アイレム",
  "releaseDate": "1987/3/1",
  "price": "500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "42",
  "ini": "め",
  "name": "メルヘンヴェール",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1987/3/3",
  "price": "3200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "43",
  "ini": "す",
  "name": "スーパーロードランナー",
  "maker": "アイレム",
  "releaseDate": "1987/3/5",
  "price": "3100",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "44",
  "ini": "と",
  "name": "とびだせ大作戦",
  "maker": "DOG（スクウェア）",
  "releaseDate": "1987/3/12",
  "price": "3400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "45",
  "ini": "う",
  "name": "ウインターゲームズ",
  "maker": "ポニーキャニオン",
  "releaseDate": "1987/3/27",
  "price": "2900",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "46",
  "ini": "お",
  "name": "オトッキー",
  "maker": "アスキー",
  "releaseDate": "1987/3/27",
  "price": "3500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "47",
  "ini": "す",
  "name": "スーパーボーイ アラン",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1987/3/27",
  "price": "4900",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "48",
  "ini": "ち",
  "name": "地底大陸オルドーラ",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1987/3/27",
  "price": "4900",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "49",
  "ini": "た",
  "name": "ダーティペア プロジェクトエデン",
  "maker": "バンダイ",
  "releaseDate": "1987/3/28",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "50",
  "ini": "あ",
  "name": "アップルタウン物語 Little Computer People",
  "maker": "DOG（スクウェア）",
  "releaseDate": "1987/4/3",
  "price": "3400",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "51",
  "ini": "く",
  "name": "グリーンベレー",
  "maker": "コナミ",
  "releaseDate": "1987/4/10",
  "price": "2980",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "52",
  "ini": "こ",
  "name": "ココナワールド",
  "maker": "ソフエル",
  "releaseDate": "1987/4/10",
  "price": "2980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "53",
  "ini": "れ",
  "name": "レリクス 暗黒要塞",
  "maker": "ボースティック",
  "releaseDate": "1987/4/10",
  "price": "4200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "54",
  "ini": "い",
  "name": "囲碁九路盤対局",
  "maker": "BPS",
  "releaseDate": "1987/4/14",
  "price": "3500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "55",
  "ini": "ふ",
  "name": "風雲少林拳",
  "maker": "ジャレコ",
  "releaseDate": "1987/4/17",
  "price": "3200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "56",
  "ini": "あ",
  "name": "愛戦士ニコル",
  "maker": "コナミ",
  "releaseDate": "1987/4/24",
  "price": "2980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "57",
  "ini": "た",
  "name": "探偵 神宮寺三郎 新宿中央公園殺人事件",
  "maker": "データイースト",
  "releaseDate": "1987/4/24",
  "price": "3300",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "58",
  "ini": "き",
  "name": "キン肉マン キン肉星王位争奪戦",
  "maker": "バンダイ",
  "releaseDate": "1987/5/1",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "59",
  "ini": "は",
  "name": "ハオ君の不思議な旅",
  "maker": "DOG（スクウェア）",
  "releaseDate": "1987/5/1",
  "price": "3400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "60",
  "ini": "せ",
  "name": "聖剣サイコカリバー",
  "maker": "イマジニア",
  "releaseDate": "1987/5/19",
  "price": "4900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "61",
  "ini": "せ",
  "name": "セクションZ",
  "maker": "カプコン",
  "releaseDate": "1987/5/25",
  "price": "2980",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "62",
  "ini": "ふ",
  "name": "プロゴルファー猿 影のトーナメント",
  "maker": "バンダイ",
  "releaseDate": "1987/5/25",
  "price": "3300",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "63",
  "ini": "め",
  "name": "迷宮寺院ダババ",
  "maker": "コナミ",
  "releaseDate": "1987/5/29",
  "price": "2980",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "64",
  "ini": "す",
  "name": "スマッシュピンポン",
  "maker": "任天堂",
  "releaseDate": "1987/5/30",
  "price": "2500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "65",
  "ini": "ゆ",
  "name": "勇士の紋章 ディープダンジョンⅡ",
  "maker": "DOG（スクウェア）",
  "releaseDate": "1987/5/30",
  "price": "3400",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "66",
  "ini": "り",
  "name": "リフレクトワールド",
  "maker": "イーストキューブ",
  "releaseDate": "1987/6/2",
  "price": "3200",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "67",
  "ini": "な",
  "name": "ナゾラーランド 第2号",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1987/6/12",
  "price": "2980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "68",
  "ini": "こ",
  "name": "ゴルフ USコース",
  "maker": "任天堂",
  "releaseDate": "1987/6/14",
  "price": "3500",
  "genre": "スポーツ",
  "icNo": "2"
 },
 {
  "id": "69",
  "ini": "と",
  "name": "ドクター・カオス 地獄の扉",
  "maker": "ポニーキャニオン",
  "releaseDate": "1987/6/19",
  "price": "2900",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "70",
  "ini": "ま",
  "name": "マイケルEnglish大冒険",
  "maker": "スコーピオンソフト",
  "releaseDate": "1987/6/19",
  "price": "2980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "71",
  "ini": "え",
  "name": "エキサイティングビリヤード",
  "maker": "コナミ",
  "releaseDate": "1987/6/26",
  "price": "2980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "72",
  "ini": "し",
  "name": "磁界少年メット・マグ",
  "maker": "DOG（スクウェア）",
  "releaseDate": "1987/7/3",
  "price": "3400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "73",
  "ini": "ゆ",
  "name": "夢工場ドキドキパニック",
  "maker": "フジテレビ、任天堂",
  "releaseDate": "1987/7/10",
  "price": "2980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "74",
  "ini": "え",
  "name": "エキサイティングバスケット",
  "maker": "コナミ",
  "releaseDate": "1987/7/24",
  "price": "2980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "75",
  "ini": "く",
  "name": "クレオパトラの魔宝",
  "maker": "DOG（スクウェア）",
  "releaseDate": "1987/7/24",
  "price": "3300",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "76",
  "ini": "た",
  "name": "タイタニックミステリー 蒼の戦慄",
  "maker": "学習研究社",
  "releaseDate": "1987/7/24",
  "price": "3200",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "77",
  "ini": "も",
  "name": "モンティのドキドキ大脱走",
  "maker": "ジャレコ",
  "releaseDate": "1987/7/31",
  "price": "3200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "78",
  "ini": "ま",
  "name": "麻雀家族",
  "maker": "アイレム",
  "releaseDate": "1987/8/4",
  "price": "2980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "79",
  "ini": "あ",
  "name": "アルマナの奇跡",
  "maker": "コナミ",
  "releaseDate": "1987/8/11",
  "price": "2980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "80",
  "ini": "す",
  "name": "スーパーロードランナーⅡ",
  "maker": "アイレム",
  "releaseDate": "1987/8/25",
  "price": "500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "81",
  "ini": "と",
  "name": "ドラキュラⅡ 呪いの封印",
  "maker": "コナミ",
  "releaseDate": "1987/8/28",
  "price": "2980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "82",
  "ini": "と",
  "name": "トランスフォーマー ザ・ヘッドマスターズ",
  "maker": "タカラ",
  "releaseDate": "1987/8/28",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "83",
  "ini": "き",
  "name": "奇々怪界 怒涛編",
  "maker": "タイトー",
  "releaseDate": "1987/8/28",
  "price": "3800",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "84",
  "ini": "ふ",
  "name": "ふぁみこんむかし話 新・鬼ヶ島 前編",
  "maker": "任天堂",
  "releaseDate": "1987/9/4",
  "price": "2600",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "85",
  "ini": "ふ",
  "name": "ふぁみこんむかし話 新・鬼ヶ島 後編",
  "maker": "任天堂",
  "releaseDate": "1987/9/30",
  "price": "2500",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "86",
  "ini": "か",
  "name": "カリーンの剣",
  "maker": "DOG（スクウェア）",
  "releaseDate": "1987/10/2",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "87",
  "ini": "は",
  "name": "パルサーの光",
  "maker": "ソフトプロ",
  "releaseDate": "1987/10/2",
  "price": "2980",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "88",
  "ini": "と",
  "name": "トップルジップ",
  "maker": "ボースティック",
  "releaseDate": "1987/10/9",
  "price": "4200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "89",
  "ini": "ふ",
  "name": "ファルシオン",
  "maker": "コナミ",
  "releaseDate": "1987/10/21",
  "price": "2980",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "90",
  "ini": "よ",
  "name": "妖怪屋敷",
  "maker": "アイレム",
  "releaseDate": "1987/10/23",
  "price": "3100",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "91",
  "ini": "は",
  "name": "バブルボブル",
  "maker": "タイトー",
  "releaseDate": "1987/10/30",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "92",
  "ini": "ふ",
  "name": "ファミコングランプリ F1レース",
  "maker": "任天堂",
  "releaseDate": "1987/10/30",
  "price": "3500",
  "genre": "レース",
  "icNo": "2"
 },
 {
  "id": "93",
  "ini": "ふ",
  "name": "ファミリーコンポーザー",
  "maker": "東京書籍",
  "releaseDate": "1987/10/30",
  "price": "2980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "94",
  "ini": "た",
  "name": "谷川浩司の将棋指南Ⅱ",
  "maker": "ポニーキャニオン",
  "releaseDate": "1987/11/13",
  "price": "3000",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "95",
  "ini": "き",
  "name": "キック・チャレンジャー エアフット 野菜の国の足戦士",
  "maker": "バップ",
  "releaseDate": "1987/11/20",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "96",
  "ini": "え",
  "name": "SDガンダムワールド ガチャポン戦士 スクランブルウォーズ",
  "maker": "バンダイ",
  "releaseDate": "1987/11/20",
  "price": "3300",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "97",
  "ini": "な",
  "name": "中山美穂のトキメキハイスクール",
  "maker": "任天堂",
  "releaseDate": "1987/12/1",
  "price": "3500",
  "genre": "アドベンチャー",
  "icNo": "2"
 },
 {
  "id": "98",
  "ini": "さ",
  "name": "サンタクロースの宝箱",
  "maker": "データイースト",
  "releaseDate": "1987/12/4",
  "price": "3300",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "99",
  "ini": "と",
  "name": "ドレミッコ",
  "maker": "コナミ",
  "releaseDate": "1987/12/4",
  "price": "8980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "100",
  "ini": "え",
  "name": "エキサイティングベースボール",
  "maker": "コナミ",
  "releaseDate": "1987/12/8",
  "price": "2980",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "101",
  "ini": "け",
  "name": "極楽遊戯（げーむてんごく）",
  "maker": "ソフエル",
  "releaseDate": "1987/12/12",
  "price": "3300",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "102",
  "ini": "う",
  "name": "ウルトラマン2 出撃科特隊",
  "maker": "バンダイ",
  "releaseDate": "1987/12/18",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "103",
  "ini": "な",
  "name": "ナゾラーランドスペシャル!! 「クイズ王を探せ」",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1987/12/18",
  "price": "2980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "104",
  "ini": "え",
  "name": "SDガンダムワールド ガチャポン戦士 スクランブルウォーズ",
  "maker": "バンダイ",
  "releaseDate": "1988/1/20",
  "price": "500",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "105",
  "ini": "あ",
  "name": "アイスホッケー",
  "maker": "任天堂",
  "releaseDate": "1988/1/21",
  "price": "2500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "106",
  "ini": "か",
  "name": "ガンスモーク",
  "maker": "カプコン",
  "releaseDate": "1988/1/27",
  "price": "3300",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "107",
  "ini": "ふ",
  "name": "ファイヤーバム",
  "maker": "HAL研究所",
  "releaseDate": "1988/2/1",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "108",
  "ini": "え",
  "name": "エキサイティングサッカー コナミカップ",
  "maker": "コナミ",
  "releaseDate": "1988/2/16",
  "price": "3300",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "109",
  "ini": "れ",
  "name": "レプリカート",
  "maker": "タイトー",
  "releaseDate": "1988/2/26",
  "price": "3500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "110",
  "ini": "と",
  "name": "ドルイド 恐怖の扉",
  "maker": "ジャレコ",
  "releaseDate": "1988/3/3",
  "price": "3200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "111",
  "ini": "ぬ",
  "name": "19（ヌイーゼン）",
  "maker": "ソフトプロ",
  "releaseDate": "1988/3/4",
  "price": "2980",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "112",
  "ini": "つ",
  "name": "ツインビー",
  "maker": "コナミ",
  "releaseDate": "1988/3/11",
  "price": "500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "113",
  "ini": "な",
  "name": "ナゾラーラン ド第3号",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1988/3/11",
  "price": "2980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "114",
  "ini": "お",
  "name": "おもいっきり探偵団 覇悪怒組 魔天郎の挑戦状",
  "maker": "バンダイ",
  "releaseDate": "1988/3/25",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "115",
  "ini": "わ",
  "name": "ワードナの森",
  "maker": "タイトー",
  "releaseDate": "1988/3/25",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "116",
  "ini": "あ",
  "name": "アスピック 魔蛇王の呪い",
  "maker": "ボースティック",
  "releaseDate": "1988/3/31",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "117",
  "ini": "と",
  "name": "ドンキーコング",
  "maker": "任天堂",
  "releaseDate": "1988/4/8",
  "price": "500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "118",
  "ini": "く",
  "name": "グーニーズ",
  "maker": "コナミ",
  "releaseDate": "1988/4/8",
  "price": "500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "119",
  "ini": "ふ",
  "name": "ファミコングランプリⅡ 3Dホットラリー ",
  "maker": "任天堂",
  "releaseDate": "1988/4/14",
  "price": "3500",
  "genre": "レース",
  "icNo": "2"
 },
 {
  "id": "120",
  "ini": "か",
  "name": "仮面ライダーBLACK 対決 シャドームーン",
  "maker": "バンダイ",
  "releaseDate": "1988/4/15",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "121",
  "ini": "は",
  "name": "バイオミラクル ぼくってウパ",
  "maker": "コナミ",
  "releaseDate": "1988/4/22",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "122",
  "ini": "ふ",
  "name": "風雲少林拳 暗黒の魔王",
  "maker": "ジャレコ",
  "releaseDate": "1988/4/22",
  "price": "3200",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "123",
  "ini": "ふ",
  "name": "ファミコン探偵倶楽部 消えた後継者 前編",
  "maker": "任天堂",
  "releaseDate": "1988/4/27",
  "price": "2600",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "124",
  "ini": "ふ",
  "name": "ファイナルコマンド 赤い要塞",
  "maker": "コナミ",
  "releaseDate": "1988/5/2",
  "price": "3300",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "125",
  "ini": "ふ",
  "name": "ファミコン探偵倶楽部 消えた後継者 後編",
  "maker": "任天堂",
  "releaseDate": "1988/6/14",
  "price": "2600",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "126",
  "ini": "ふ",
  "name": "ファイヤーロック",
  "maker": "ユース",
  "releaseDate": "1988/6/20",
  "price": "3400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "127",
  "ini": "り",
  "name": "リサの妖精伝説",
  "maker": "コナミ",
  "releaseDate": "1988/6/21",
  "price": "3500",
  "genre": "アドベンチャー",
  "icNo": "2"
 },
 {
  "id": "128",
  "ini": "む",
  "name": "ムーンボールマジック",
  "maker": "DOG（スクウェア）",
  "releaseDate": "1988/7/12",
  "price": "500",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "129",
  "ini": "あ",
  "name": "亜空戦記ライジン",
  "maker": "DOG（スクウェア）",
  "releaseDate": "1988/7/12",
  "price": "500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "130",
  "ini": "と",
  "name": "遠山の金さん すぺえす帖 Mr. GOLD",
  "maker": "東映動画",
  "releaseDate": "1988/7/19",
  "price": "3400",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "131",
  "ini": "と",
  "name": "ドンキーコングJR.",
  "maker": "任天堂",
  "releaseDate": "1988/7/19",
  "price": "500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "132",
  "ini": "か",
  "name": "カラテ チャンプ",
  "maker": "データイースト",
  "releaseDate": "1988/7/22",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "133",
  "ini": "こ",
  "name": "コナミックアイスホッケー",
  "maker": "コナミ",
  "releaseDate": "1988/7/22",
  "price": "3300",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "134",
  "ini": "ら",
  "name": "らじかるぼんばー!! 地雷くん",
  "maker": "ジャレコ",
  "releaseDate": "1988/7/29",
  "price": "3200",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "135",
  "ini": "は",
  "name": "爆闘士パットンくん",
  "maker": "ソフトプロ",
  "releaseDate": "1988/8/5",
  "price": "2980",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "136",
  "ini": "し",
  "name": "シルヴィアーナ 愛いっぱいの冒険者",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1988/8/10",
  "price": "3500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "137",
  "ini": "た",
  "name": "谷川浩司の将棋指南Ⅱ 新版 つめ将棋・次の一手",
  "maker": "ポニーキャニオン",
  "releaseDate": "1988/8/10",
  "price": "500",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "138",
  "ini": "ひ",
  "name": "ビッグチャレンジ！ 柔道選手権",
  "maker": "ジャレコ",
  "releaseDate": "1988/8/10",
  "price": "3200",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "139",
  "ini": "こ",
  "name": "コナミックテニス",
  "maker": "コナミ",
  "releaseDate": "1988/8/19",
  "price": "3300",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "140",
  "ini": "え",
  "name": "エッガーランド 創造への旅立ち",
  "maker": "HAL研究所",
  "releaseDate": "1988/8/20",
  "price": "500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "141",
  "ini": "ま",
  "name": "魔塔の崩壊",
  "maker": "ポニーキャニオン",
  "releaseDate": "1988/9/2",
  "price": "3000",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "142",
  "ini": "き",
  "name": "キック アンド ラン",
  "maker": "タイトー",
  "releaseDate": "1988/9/13",
  "price": "3300",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "143",
  "ini": "は",
  "name": "バーガータイム",
  "maker": "データイースト",
  "releaseDate": "1988/9/23",
  "price": "500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "144",
  "ini": "は",
  "name": "パチコン",
  "maker": "東芝EMI",
  "releaseDate": "1988/10/4",
  "price": "500",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "145",
  "ini": "た",
  "name": "ダンディー　ゼウオンの復活",
  "maker": "ポニーキャニオン",
  "releaseDate": "1988/10/21",
  "price": "3000",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "146",
  "ini": "ひ",
  "name": "ビッグチャレンジ！ ドッグファイトスピリット",
  "maker": "ジャレコ",
  "releaseDate": "1988/10/21",
  "price": "3200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "147",
  "ini": "う",
  "name": "ウルトラマン倶楽部 地球奪還作戦",
  "maker": "バンダイ",
  "releaseDate": "1988/10/22",
  "price": "3300",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "148",
  "ini": "ゆ",
  "name": "遊メイズ",
  "maker": "タイトー",
  "releaseDate": "1988/10/28",
  "price": "3300",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "149",
  "ini": "さ",
  "name": "サムライソード",
  "maker": "カプコン",
  "releaseDate": "1988/11/15",
  "price": "3300",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "150",
  "ini": "あ",
  "name": "アイスクライマー",
  "maker": "任天堂",
  "releaseDate": "1988/11/18",
  "price": "500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "151",
  "ini": "し",
  "name": "ジャイラス",
  "maker": "コナミ",
  "releaseDate": "1988/11/18",
  "price": "3300",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "152",
  "ini": "は",
  "name": "パチンコGP",
  "maker": "データイースト",
  "releaseDate": "1988/11/18",
  "price": "3300",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "153",
  "ini": "し",
  "name": "じゃあまん探偵団 魔隣組 丸秘ジゴマ捜査ファイル",
  "maker": "バンダイ",
  "releaseDate": "1988/11/29",
  "price": "3300",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "154",
  "ini": "か",
  "name": "帰ってきたマリオブラザーズ",
  "maker": "任天堂",
  "releaseDate": "1988/11/30",
  "price": "400",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "155",
  "ini": "ふ",
  "name": "VS.エキサイトバイク",
  "maker": "任天堂",
  "releaseDate": "1988/12/9",
  "price": "2500",
  "genre": "レース",
  "icNo": "1"
 },
 {
  "id": "156",
  "ini": "な",
  "name": "なんきんのアドベンチア",
  "maker": "サンソフト（サン電子）",
  "releaseDate": "1988/12/9",
  "price": "3200",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "157",
  "ini": "た",
  "name": "探偵 神宮寺三郎 危険な二人 前編",
  "maker": "データイースト",
  "releaseDate": "1988/12/9",
  "price": "3300",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "158",
  "ini": "た",
  "name": "タロット占い",
  "maker": "スコーピオンソフト",
  "releaseDate": "1988/12/23",
  "price": "2980",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "159",
  "ini": "は",
  "name": "ハレーウォーズ",
  "maker": "タイトー",
  "releaseDate": "1989/1/14",
  "price": "3300",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "160",
  "ini": "き",
  "name": "機動警察パトレイバー 第2小隊出動せよ！",
  "maker": "バンダイ",
  "releaseDate": "1989/1/24",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "161",
  "ini": "れ",
  "name": "レッキングクルー",
  "maker": "任天堂",
  "releaseDate": "1989/2/3",
  "price": "500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "162",
  "ini": "た",
  "name": "探偵 神宮寺三郎 危険な二人 後編",
  "maker": "データイースト",
  "releaseDate": "1989/2/10",
  "price": "3300",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "163",
  "ini": "ろ",
  "name": "ロジャーラビット",
  "maker": "コトブキシステム",
  "releaseDate": "1989/2/16",
  "price": "3500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "164",
  "ini": "た",
  "name": "タマ&フレンズ 3丁目大冒険",
  "maker": "バンダイ",
  "releaseDate": "1989/2/23",
  "price": "3300",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "165",
  "ini": "え",
  "name": "SDガンダムワールド ガチャポン戦士 スクランブルウォーズ マップコレクション",
  "maker": "バンダイ",
  "releaseDate": "1989/3/3",
  "price": "500",
  "genre": "シミュレーション",
  "icNo": "1"
 },
 {
  "id": "166",
  "ini": "ひ",
  "name": "光GENJI ローラーパニック",
  "maker": "ポニーキャニオン",
  "releaseDate": "1989/3/20",
  "price": "3200",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "167",
  "ini": "は",
  "name": "パットパットゴルフ",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1989/3/30",
  "price": "3500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "168",
  "ini": "ひ",
  "name": "ビッグチャレンジ！ ガンファイター",
  "maker": "ジャレコ",
  "releaseDate": "1989/3/28",
  "price": "3200",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "169",
  "ini": "ふ",
  "name": "フェアリーテイル",
  "maker": "ソフトプロ",
  "releaseDate": "1989/4/28",
  "price": "3200",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "170",
  "ini": "ふ",
  "name": "ファミコン探偵倶楽部 PARTⅡ うしろに立つ少女 前編",
  "maker": "任天堂",
  "releaseDate": "1989/5/23",
  "price": "2600",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "171",
  "ini": "ひ",
  "name": "ピンボール",
  "maker": "任天堂",
  "releaseDate": "1989/5/30",
  "price": "500",
  "genre": "その他",
  "icNo": "1"
 },
 {
  "id": "172",
  "ini": "ひ",
  "name": "ビッグチャレンジ！ GO！GO！ボウリング",
  "maker": "ジャレコ",
  "releaseDate": "1989/6/23",
  "price": "3200",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "173",
  "ini": "ふ",
  "name": "ファミコン探偵倶楽部 PARTⅡ うしろに立つ少女 後編",
  "maker": "任天堂",
  "releaseDate": "1989/6/30",
  "price": "2600",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "174",
  "ini": "ま",
  "name": "マグマプロジェクト ハッカー",
  "maker": "徳間書店",
  "releaseDate": "1989/8/10",
  "price": "3500",
  "genre": "RPG",
  "icNo": "1"
 },
 {
  "id": "175",
  "ini": "ふ",
  "name": "ふぁみこんむかし話 遊遊記 前編",
  "maker": "任天堂",
  "releaseDate": "1989/10/14",
  "price": "2600",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "176",
  "ini": "か",
  "name": "かっとび！童児",
  "maker": "パック・イン・ビデオ",
  "releaseDate": "1989/10/20",
  "price": "3500",
  "genre": "スポーツ",
  "icNo": "1"
 },
 {
  "id": "177",
  "ini": "ふ",
  "name": "ふぁみこんむかし話 遊遊記 後編",
  "maker": "任天堂",
  "releaseDate": "1989/11/14",
  "price": "2600",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "178",
  "ini": "り",
  "name": "LUTTER",
  "maker": "アテナ",
  "releaseDate": "1989/11/24",
  "price": "500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "179",
  "ini": "ほ",
  "name": "香港 ファミマガDisk Vol.1",
  "maker": "徳間書店",
  "releaseDate": "1990/3/23",
  "price": "2980",
  "genre": "テーブル",
  "icNo": "1"
 },
 {
  "id": "180",
  "ini": "ほ",
  "name": "ボンバーマン",
  "maker": "ハドソン",
  "releaseDate": "1990/4/24",
  "price": "500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "181",
  "ini": "せ",
  "name": "ゼビウス",
  "maker": "ナムコ",
  "releaseDate": "1990/5/18",
  "price": "500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "182",
  "ini": "は",
  "name": "パックマン",
  "maker": "ナムコ",
  "releaseDate": "1990/5/18",
  "price": "500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "183",
  "ini": "な",
  "name": "ナイトムーブ",
  "maker": "任天堂",
  "releaseDate": "1990/6/5",
  "price": "2600",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "184",
  "ini": "き",
  "name": "ギャラガ",
  "maker": "ナムコ",
  "releaseDate": "1990/6/22",
  "price": "500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "185",
  "ini": "き",
  "name": "ギャラクシアン",
  "maker": "ナムコ",
  "releaseDate": "1990/7/20",
  "price": "500",
  "genre": "シューティング",
  "icNo": "1"
 },
 {
  "id": "186",
  "ini": "て",
  "name": "ディグダグ",
  "maker": "ナムコ",
  "releaseDate": "1990/7/20",
  "price": "500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "187",
  "ini": "て",
  "name": "ディグダグⅡ",
  "maker": "ナムコ",
  "releaseDate": "1990/8/31",
  "price": "500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "188",
  "ini": "は",
  "name": "バックギャモン",
  "maker": "任天堂",
  "releaseDate": "1990/9/7",
  "price": "2600",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "189",
  "ini": "は",
  "name": "パニックスペース ファミマガDisk Vol.2",
  "maker": "徳間書店",
  "releaseDate": "1990/10/19",
  "price": "500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "190",
  "ini": "は",
  "name": "パズルボーイズ",
  "maker": "アトラス",
  "releaseDate": "1990/11/16",
  "price": "500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "191",
  "ini": "い",
  "name": "石道（ishido）",
  "maker": "ヒロ",
  "releaseDate": "1990/12/7",
  "price": "500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "192",
  "ini": "そ",
  "name": "ソロモンの鍵",
  "maker": "テクモ",
  "releaseDate": "1991/1/25",
  "price": "500",
  "genre": "アクション",
  "icNo": "1"
 },
 {
  "id": "193",
  "ini": "お",
  "name": "オール1 ファミマガDisk Vol.3",
  "maker": "徳間書店",
  "releaseDate": "1991/2/22",
  "price": "500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "194",
  "ini": "く",
  "name": "クロックス ファミマガDisk Vol.4",
  "maker": "徳間書店",
  "releaseDate": "1991/4/19",
  "price": "500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "195",
  "ini": "た",
  "name": "タイムツイスト 歴史のかたすみで… 前編",
  "maker": "任天堂",
  "releaseDate": "1991/7/26",
  "price": "2600",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "196",
  "ini": "た",
  "name": "タイムツイスト 歴史のかたすみで… 後編",
  "maker": "任天堂",
  "releaseDate": "1991/7/26",
  "price": "2600",
  "genre": "アドベンチャー",
  "icNo": "1"
 },
 {
  "id": "197",
  "ini": "ふ",
  "name": "ぷよぷよ  ファミマガDisk Vol.5",
  "maker": "徳間書店",
  "releaseDate": "1991/10/25",
  "price": "600",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "198",
  "ini": "く",
  "name": "クルクルランド",
  "maker": "任天堂",
  "releaseDate": "1992/4/28",
  "price": "500",
  "genre": "パズル",
  "icNo": "1"
 },
 {
  "id": "199",
  "ini": "し",
  "name": "じゃんけんディスク城 ファミマガDisk Vol.6",
  "maker": "徳間書店",
  "releaseDate": "1992/12/22",
  "price": "500",
  "genre": "パズル",
  "icNo": "1"
 }
];